import { Button } from '@/components/ui/button';
import { DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuGroup, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useSettingsStore } from '@/stores/settingsStore';
import { DropdownMenu } from '@radix-ui/react-dropdown-menu';
import { AlertCircle, PrinterIcon } from 'lucide-react';
import { getLabelValue } from '@/utils/getLabelValue';
import { useEffect } from 'react';
import { SignalRContext } from '@/providers/SignalRProvider';
import { Printer } from '../types/printer';
import { BatchPrintDialog } from '@/components/batch-print-dialog';
import { useToast } from '@/components/ui/use-toast';

export function PrinterDropdown() {
  const eventId = useSettingsStore(state => state.eventId) ?? 0;

  const { toast } = useToast();

  const selectedPrinter = useSettingsStore(state => state.printer);
  const printers = useSettingsStore(state => state.printers);

  const setPrinter = useSettingsStore(state => state.setPrinter);
  const setPrinters = useSettingsStore(state => state.setPrinters);

  const onPrinterConnected = async (printer: Printer) => {
    if (printer.name === selectedPrinter?.name) {
      setPrinter(printer);
      toast({ title: getLabelValue('PrinterConnected'), variant: 'success' });
    }

    setPrinters([...printers, printer]);

 
  };

  const onPrinterDisconnected = async () => {
    setPrinters(printers.filter(printer => printer.clientGuid !== selectedPrinter?.clientGuid));
    toast({ title: getLabelValue('PrinterDisconnected'), variant: 'destructive' });
  };

  const onGetActivePrintClients = async (printers: Printer[]) => {
    setPrinters(printers);
  };

  SignalRContext.useSignalREffect('OnPrinterConnected', onPrinterConnected, [selectedPrinter]);
  SignalRContext.useSignalREffect('OnPrinterDisconnected', onPrinterDisconnected, [selectedPrinter]);
  SignalRContext.useSignalREffect('OnGetActivePrintClients', onGetActivePrintClients, [printers]);

  useEffect(() => {
    if (SignalRContext.connection?.state !== 'Connected') return;
    (async () => {
      try {
        await SignalRContext.invoke('GetActivePrintClients', eventId);
      } catch (error) {
        toast({ title: getLabelValue('FailedToGetActivePrintClients'), variant: 'destructive' });
      }
    })();
  }, [SignalRContext.connection?.state]);

  const isConnected = printers?.some(printer => printer.clientGuid === selectedPrinter?.clientGuid);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant="outline">
          {isConnected ? <PrinterIcon className="h-4 w-4 mr-1" /> : <AlertCircle className="h-4 w-4 mr-1 mt-0.5 text-red-500" />}
          {isConnected ? selectedPrinter?.name : getLabelValue('NoPrinterSelected')}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-64">
        <DropdownMenuLabel className="flex items-center">{getLabelValue('SelectPrinter')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {printers?.length > 0 ? (
          printers.map(printer => (
            <DropdownMenuCheckboxItem
              checked={printer.clientGuid === selectedPrinter?.clientGuid}
              onClick={() => setPrinter(printer.clientGuid === selectedPrinter?.clientGuid ? null : printer)}
            >
              {printer.name}
            </DropdownMenuCheckboxItem>
          ))
        ) : (
          <DropdownMenuLabel className="font-normal">{getLabelValue('NoPrintersFound')}</DropdownMenuLabel>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuGroup className="p-2">
          <BatchPrintDialog />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
