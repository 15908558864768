import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useEffect, useRef, useState } from 'react';
import { useAttendeeSearchResult } from '../api/get-attendee-search-result';
import { useSettingsStore } from '@/stores/settingsStore';
import { getLabelValue } from '@/utils/getLabelValue';
import { InfoIcon, SearchCheckIcon, SearchIcon, X } from 'lucide-react';
import { cn } from '@/utils';
import { SearchResultItem } from './search-result-item';
import { Skeleton } from '@/components/ui/skeleton';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { PassageResult } from './passage-result';
import { usePassage } from '@/hooks/use-passage';
import { PassageError } from './passage-error';
import { ScrollArea } from '@/components/ui/scroll-area';

export function Search() {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [searchKey, setSearchKey] = useState('');
  const [badgeNo, setBadgeNo] = useState<string | null>();

  const { pass, passage, error } = usePassage();

  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const searchEnabledCondition = searchKey.length > 2;

  const {
    data: searchResult = [],
    isLoading,
    isError,
  } = useAttendeeSearchResult({
    searchKey,
    eventId,
    config: {
      enabled: searchEnabledCondition,
    },
  });

  const handleKeyDown = (event: any) => {
    if (event.target?.localName !== 'input') {
      // searchInput?.focus();
    }

    setBadgeNo(prev => (prev ? (prev += event.key) : event.key));

    // const timeout = setTimeout(() => {
    //   setIsKeyDown(false);
    //   clearTimeout(timeout);
    // }, 1000);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (badgeNo && badgeNo.endsWith('Enter') && badgeNo.startsWith(eventId.toString())) {
      const trimmedBadgeNo = badgeNo.replace('Enter', '');
      pass(trimmedBadgeNo);
      setBadgeNo(null);
    }
  }, [badgeNo]);

  return (
    <div className={cn('flex flex-col pt-6 justify-start items-center h-full relative  mx-auto', searchKey?.length > 2 ? 'space-y-0' : 'space-y-6')}>
      {passage && (
        <div className={cn(passage ? 'opacity-100 h-auto' : 'opacity-0 h-0', 'transition-all duration-500')}>
          <PassageResult attendee={searchResult.find(result => result.badgeNo === passage.checkpointData?.badgeNo)} checkpointData={passage.checkpointData} />
        </div>
      )}
      {error && <PassageError message={error} />}
      <div className={cn('space-y-4 transition-all  duration-75 max-w-7xl mx-auto', searchKey?.length > 2 ? '-mt-16' : 'mt-8')}>
        <Label className={cn('text-3xl flex justify-center w-full transition-all duration-500', searchKey?.length < 3 ? 'opacity-100 h-auto' : 'opacity-0 h-0')}>
          {getLabelValue('SearchAttendeeInputTitle')}
        </Label>
        <div className="space-y-2 flex justify-center flex-col ">
          <div className="relative">
            <SearchIcon className="absolute top-1/2 left-3 -translate-y-1/2" />
            <Input
              value={searchKey}
              onChange={e => setSearchKey(e.target.value)}
              ref={searchInputRef}
              placeholder="Sök"
              className={cn('w-[500px] border-0 p-6 pl-12 bg-accent shadow-sm  rounded-full  ', searchKey?.length < 3 ? 'text-md' : 'text-lg')}
            />
            {searchKey.length > 0 && (
              <Button onClick={() => setSearchKey('')} variant="ghost" className="w-fit h-fit text-muted-foreground rounded-full absolute z-10 top-1/2 right-3 -translate-y-1/2">
                <X className="w-6 h-6" />
              </Button>
            )}
          </div>
          <Popover>
            <PopoverTrigger
              className={cn(
                'flex items-center justify-center gap-1  text-muted-foreground  transition-all duration-500',
                searchKey?.length < 3 ? 'opacity-100 h-auto' : 'opacity-0 h-0'
              )}
            >
              <span className="italic">{getLabelValue('SearchKeyInfo')}</span>
              <InfoIcon className="w-4 h-4" />
            </PopoverTrigger>
            <PopoverContent className="w-fit flex items-center">
              <SearchCheckIcon className="w-4 h-4 mr-2" />
              {getLabelValue('AvailableSearchKeys')}
            </PopoverContent>
          </Popover>
          {!isLoading && searchResult.length > 0 && (
            <div className="text-muted-foreground text-center">
              {searchResult.length} {getLabelValue('SearchResults')}
            </div>
          )}
          {!isLoading && searchResult.length === 0 && searchKey != '' && <div className="text-muted-foreground text-center">{getLabelValue('NoResults')}</div>}
        </div>
      </div>

      {searchKey === '' ? (
        <div className="text-muted-foreground max-w-4xl text-center flex flex-col gap-4">
          <div className="relative w-32 h-32 flex items-center bg-red-50 rounded-full p-1 mx-auto">
            <img src="https://cdn-icons-png.flaticon.com/512/10994/10994921.png" alt="barcode" className="w-24 h-24 mx-auto " />
          </div>
          <span className="w-96">{getLabelValue('PassageInfo')}</span>
        </div>
      ) : (
        <>
          <ScrollArea style={{ boxShadow: '0 -4px 6px var(--bg-background)' }} className="overflow-y-auto max-h-[calc(100vh_-_320px)] w-full  py-6">
            <div className="max-w-2xl mx-auto space-y-2">
              {!isLoading ? (
                searchResult
                  ?.sort((a, b) => (a.searchScore > b.searchScore ? 1 : -1))
                  ?.map(result => <SearchResultItem key={result.badgeNo} searchResult={result} onPassing={pass} />)
              ) : (
                <>
                  <Skeleton className="w-full h-[65px]" />
                  <Skeleton className="w-full h-[65px]" />
                  <Skeleton className="w-full h-[65px]" />
                  <Skeleton className="w-full h-[65px]" />
                  <Skeleton className="w-full h-[65px]" />
                </>
              )}
            </div>
          </ScrollArea>
        </>
      )}
    </div>
  );
}
