import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '@/components/ui/dialog';
import { AttendeeSearchResult } from '../types/attendee-search-result';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Briefcase, Mail, Search } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { Card, CardContent, CardDescription, CardFooter, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { getLabelValue } from '@/utils/getLabelValue';
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useOnsiteStats } from '../api/get-onsite-stats';
import { useSettingsStore } from '@/stores/settingsStore';
import { formatDate } from '@/utils/format-date';

interface AttendeeDetailsDialogProps {
  attendee: AttendeeSearchResult;
}

export function AttendeeDetailsDialog({ attendee }: AttendeeDetailsDialogProps) {
  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const breakoutId = useSettingsStore(state => state.breakoutId) ?? null;

  const { data: oniteStats } = useOnsiteStats({
    eventId,
    breakoutId: breakoutId ?? undefined,
    config: {
      enabled: false,
    },
  });

  const checkins = oniteStats?.arrived?.[attendee.badgeNo] ?? [];
  const checkouts = oniteStats?.exit?.[attendee.badgeNo] ?? [];

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" variant="outline">
          <Search className="h-4 w-4 mr-1" />
          Visa detaljer
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] bg-card">
        <DialogHeader>
          <div className="flex gap-4">
            <Avatar>
              <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
              <AvatarFallback>
                {attendee.firstName[0]}
                {attendee.lastName[0]}
              </AvatarFallback>
            </Avatar>
            <div className="space-y-2">
              <div>
                <div className="font-semibold">
                  {attendee.firstName} {attendee.lastName}
                </div>
                <div className="flex flex-col text-muted-foreground">
                  <div className="flex items-center">
                    <Mail className="h-4 w-4 mr-1" />
                    {attendee.email}
                  </div>
                  {attendee.companyName && (
                    <div className="flex items-center">
                      <Briefcase className="h-4 w-4 mr-1" />
                      {attendee.companyName}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogHeader>
        <Separator />
        <div className="flex   justify-between">
          <Tabs defaultValue="checkins" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="checkins">{getLabelValue('Checkins')}</TabsTrigger>
              <TabsTrigger value="checkouts"> {getLabelValue('Checkout')}</TabsTrigger>
            </TabsList>
            <TabsContent value="checkins">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[100px]">{getLabelValue('Time')}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {checkins?.map((time: Date) => (
                    <TableRow key={time.toString()}>
                      <TableCell className="font-medium">{formatDate(time.toString())}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabsContent>
            <TabsContent value="checkouts">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[100px]">{getLabelValue('Time')}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>{checkouts?.map((time: Date) => <p className="font-medium">{time.toString()}</p>)}</TableBody>
              </Table>
            </TabsContent>
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  );
}
