import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Direction } from '../types/direction';
import { LogIn, LogOut } from 'lucide-react';
import { getLabelValue } from '@/utils/getLabelValue';

interface AttendeeEntryCard {
  attendee: any;
  checkpointData: any;
}

export function PassageResult({ attendee, checkpointData = [] }: AttendeeEntryCard) {
  const direction = checkpointData?.direction === 1 ? Direction.IN : Direction.OUT;


  return (
    <div className="flex gap-2 flex-col items-center ">
      <Avatar className="h-20 w-20">
        <AvatarImage alt="user" src={attendee?.profileImage} />
        <AvatarFallback className="text-2xl">
          {attendee?.firstName.charAt(0)}
          {attendee?.lastName.charAt(0)}
        </AvatarFallback>
      </Avatar>
      <div className="flex justify-center flex-col items-center space-y-2">
        <div className="flex items-center gap-x-2 font-semibold text-2xl">
          {attendee?.firstName} {attendee?.lastName}
        </div>
        <div className="flex items-center text-lg font-semibold">
          {direction === Direction.IN ? (
            <LogIn className="h-4 w-4 mr-2 text-emerald-500 " />
          ) : (
            <LogOut className="h-4 w-4 mr-2 text-orange-500 " />
          )}
          {direction === Direction.IN ? getLabelValue('CheckIn') : getLabelValue('CheckOut')}
        </div>
      </div>
    </div>
  );
}
