import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { OnsiteStats } from '../types/onsite-stats';

export const getOnsiteStats = (eventId: number, breakoutId?: number): Promise<OnsiteStats> => {
  return axios.get(`/${eventId}/onsite/stats`, {
    params: {
      breakoutId,
    },
  });
};

type QueryFnType = typeof getOnsiteStats;

type UseOptions = {
  breakoutId?: number;
  eventId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useOnsiteStats = ({ eventId, breakoutId, config }: UseOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: breakoutId ? ['onsite_stats', `${eventId}_${breakoutId}`] : ['onsite_stats', `${eventId}`],
    queryFn: () => getOnsiteStats(eventId, breakoutId),
    ...config,
  });
};
