import { getLabelValue } from '@/utils/getLabelValue';
import { AlertCircle } from 'lucide-react';


export function PassageError({ message }: { message: string }) {
  return (
    <div className="flex flex-col items-center gap-x-2 font-semibold text-2xl space-y-2">
      <AlertCircle className="h-12 w-12 mr-2 text-red-500 p-4 bg-red-50 rounded-full" />
      <div className="passage-error__message">{getLabelValue(message) || message}</div>
    </div>
  );
}
