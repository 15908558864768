import { HUB_URL } from '@/config';
import { hasTokenExpired, refreshToken } from '@/lib/axios';
import storage from '@/utils/storage';
import { createSignalRContext, LogLevel } from 'react-signalr/signalr';

export const SignalRContext = createSignalRContext();

SignalRContext.invoke = async (methodName: string, ...args: any[]) => {
  const connection = SignalRContext.connection;

  if (!connection) {
    throw new Error('Connection not established');
  }

  const token = storage.getToken();

  if ((token && hasTokenExpired(token)) || !token) {
    try {
      await refreshToken();
    } catch (error) {
      return Promise.reject(error); // Fail if unable to refresh
    }
  }

  return connection.invoke(methodName, ...args);
};

export function SignalRProvider({ children }: { children: React.ReactNode }) {
  return (
    <SignalRContext.Provider
      withCredentials={false}
      automaticReconnect
      timeout={60000}
      url={HUB_URL}
      logMessageContent={true}
      onError={async (error) => {
        console.error('SignalR error:', error);
        return Promise.resolve();
      }}
      accessTokenFactory={() => storage.getToken() || ''}
      dependencies={[storage.getToken()]}
      onOpen={() => console.log('Connection opened')}
    >
      {children}
    </SignalRContext.Provider>
  );
}
