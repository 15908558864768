import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';

interface CheckinProfileCardProps {
  order: any;
  checkpointData: any;
}

export function CheckinProfileCard({ order, checkpointData }: CheckinProfileCardProps) {
  return (
    <div className="flex gap-x-4 items-center">
      <Avatar>
        <AvatarImage src="https://jirangopub.s3.eu-north-1.amazonaws.com/Files/516/user.png" alt="user" />
        <AvatarFallback>CN</AvatarFallback>
      </Avatar>
      <div>
        <div className='flex items-center gap-x-2'>
          {order.firstName} {order.lastName}
        </div>
        <div className='flex items-center gap-x-2'>
          {order.email} &bull; {order.companyName}
        </div>
        {checkpointData?.direction === 1 ? <div className="text-sm text-gray-500">Incheckad</div> : <div className="text-sm text-gray-500">Utcheckad</div>}
      </div>
    </div>
  );
}
