import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { getLabelValue } from '@/utils/getLabelValue';
import { BookOpenCheck, LogIn, LogOut, MapPinned, UserCheck, UserRoundX } from 'lucide-react';
import { useOnsiteStats } from '../api/get-onsite-stats';
import { useSettingsStore } from '@/stores/settingsStore';
import { Skeleton } from '@/components/ui/skeleton';

export function OverviewCards() {
  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const breakoutId = useSettingsStore(state => state.breakoutId) ?? 0;

  const {
    data: stats,
    isLoading,
    isFetched,
  } = useOnsiteStats({
    eventId,
    breakoutId: breakoutId,
    config: {
      enabled: eventId > 0,
    },
  });

  const booked = stats?.booked ?? 0;
  const notArrived = stats?.notArrived ?? 0;
  const arrived = Object.keys(stats?.arrived ?? {}).length;
  const checkouts = Object.keys(stats?.exit ?? {}).length;
  const onsite = Object.keys(stats?.onsite ?? {}).length;

  return (
    <div className="grid gap-4 md:grid-cols-5 max-w-7xl  lg:grid-cols-5 p-6 mx-auto">
      <Card className="w-full  bg-card">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-md font-medium">{getLabelValue('Booked')}</CardTitle>
          <UserCheck
            className=" text-sky-500 bg-sky-100
          dark:bg-sky-500 dark:text-white
          h-8 w-8 p-2 rounded-full"
          />
        </CardHeader>
        <CardContent>{!isFetched || isLoading ? <Skeleton className="w-10 h-[36px]" /> : <div className="text-3xl font-bold">{booked}</div>}</CardContent>
      </Card>
      <Card className="w-full  bg-card">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-md font-medium">{getLabelValue('Arrived')}</CardTitle>
          <LogIn
            className=" text-emerald-500 bg-emerald-100 
          dark:bg-emerald-500 dark:text-white
          h-8 w-8 p-2 rounded-full"
          />
        </CardHeader>
        <CardContent>{!isFetched || isLoading ? <Skeleton className="w-10 h-[36px]" /> : <div className="text-3xl font-bold">{arrived}</div>}</CardContent>
      </Card>
      <Card className="w-full bg-card">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-md font-medium">{getLabelValue('Checkouts')}</CardTitle>
          <LogOut
            className=" text-orange-500 bg-orange-100
          dark:bg-orange-500 dark:text-white
          h-8 w-8 p-2 rounded-full"
          />
        </CardHeader>
        <CardContent>{!isFetched || isLoading ? <Skeleton className="w-10 h-[36px]" /> : <div className="text-3xl font-bold">{checkouts}</div>}</CardContent>
      </Card>
      <Card className="w-full  bg-card">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-md font-medium">{getLabelValue('NotArrived')}</CardTitle>
          <UserRoundX
            className=" text-red-500 bg-red-100 
            dark:bg-red-500 dark:text-white
          h-8 w-8 p-2 rounded-full"
          />
        </CardHeader>
        <CardContent>{isFetched && !isLoading ? <div className="text-3xl font-bold">{notArrived}</div> : <Skeleton className="w-10 h-[36px]" />}</CardContent>
      </Card>
      <Card className="w-full  bg-card">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-md font-medium">{getLabelValue('Onsite')}</CardTitle>
          <MapPinned
            className=" text-purple-500
            dark:bg-purple-500 dark:text-white
          bg-purple-100 h-8 w-8 p-2 rounded-full"
          />
        </CardHeader>
        <CardContent>{!isFetched || isLoading ? <Skeleton className="w-10 h-[36px]" /> : <div className="text-3xl font-bold">{onsite}</div>}</CardContent>
      </Card>
    </div>
  );
}
