import { Card } from '@/components/ui/card';
import { AttendeeSearchResult } from '../types/attendee-search-result';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Briefcase, LogIn, LogOut, Mail } from 'lucide-react';
import { getLabelValue } from '@/utils/getLabelValue';
import { useSettingsStore } from '@/stores/settingsStore';
import { AttendeeDetailsDialog } from './attendee-details-dialog';
import { useOnsiteStats } from '../api/get-onsite-stats';
import { Direction } from '../types/direction';

export function SearchResultItem({ searchResult, onPassing }: { searchResult: AttendeeSearchResult; onPassing: (badgeNo: string) => void }) {
  const direction = useSettingsStore(state => state.direction);
  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const breakoutId = useSettingsStore(state => state.breakoutId) ?? 0;

  const { data: stats } = useOnsiteStats({
    eventId,
    breakoutId: breakoutId,
    config: {
      enabled: eventId > 0,
    },
  });

  const isOnsite = stats?.onsite?.[searchResult.badgeNo];

  return (
    <Card className="py-2 px-4 shadow-sm dark:shadow-xl  border justify-between">
      <div className="flex justify-between items-center">
        <div className="flex gap-4 items-start">
          <Avatar>
            <AvatarImage src={searchResult?.profileImage} alt="@shadcn" />
            <AvatarFallback>
              {searchResult.firstName[0]}
              {searchResult.lastName[0]}
            </AvatarFallback>
          </Avatar>
          <div className="space-y-2">
            <div>
              <div className="font-semibold flex gap-4 items-center">
                {searchResult.firstName} {searchResult.lastName}
                {isOnsite && (
                  <div className="flex h-full items-center gap-x-3 text-xs">
                    <div className="relative flex justify-center items-center">
                      <span className="bg-emerald-500 w-2 h-2 rounded-full animate-ping absolute" />
                      <span className="bg-emerald-500 w-2 h-2 rounded-full absolute " />
                    </div>
                    På plats
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start  text-muted-foreground">
                <div className="flex items-center">
                  <Mail className="h-4 w-4 mr-1" />
                  {searchResult.email}
                </div>
                {searchResult.companyName && (
                  <div className="flex items-center">
                    <Briefcase className="h-4 w-4 mr-1" />
                    {searchResult.companyName}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex  gap-2">
          <Button size="sm" variant="outline" onClick={() => onPassing(searchResult.badgeNo)}>
            {direction === Direction.IN ? <LogIn className="h-4 w-4 mr-1 text-emerald-500" /> : <LogOut className="h-4 w-4 mr-1 text-orange-500" />}
            {direction === Direction.IN ? getLabelValue('CheckIn') : getLabelValue('CheckOut')}
          </Button>
          <AttendeeDetailsDialog attendee={searchResult} />
        </div>
      </div>

      {/* <div className="flex gap-4 p-2 px-4  text-xs bg-accent">
        <div className="flex gap-1 items-center">
          <Badge className="bg-emerald-500 hover:bg-emerald-500  text-white">4</Badge> {getLabelValue('Checkins')}
        </div>
        <div className="flex items-center gap-1">
          <Badge className="bg-orange-500 hover:bg-orange-500  text-white">1</Badge> {getLabelValue('Checkouts')}
        </div>
      </div> */}
    </Card>
  );
}
