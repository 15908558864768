import { AlertTriangle } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from './ui/alert-dialog';
import { getLabelValue } from '@/utils/getLabelValue';

interface DeleteButtonProps {
  onDelete: () => void;
  ButtonComponent: React.ReactNode;
}

export function DeleteButton({ onDelete, ButtonComponent }: DeleteButtonProps) {
  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-full text-destructive">{ButtonComponent}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center">
            <AlertTriangle className="w-6 h-6 mr-3" />
            {getLabelValue('AreYouSure')}
          </AlertDialogTitle>
          <AlertDialogDescription> {getLabelValue('DeletionDescription')}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel> {getLabelValue('Cancel')}</AlertDialogCancel>
          <AlertDialogAction className='bg-destructive' onClick={onDelete}>
            {getLabelValue('Delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
