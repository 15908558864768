import React, { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { HUB_URL } from '@/config';
import { useToast } from '@/components/ui/use-toast';
import { useSettingsStore } from '@/stores/settingsStore';
import { Printer } from '@/types';
import { queryClient } from '@/lib/react-query';
import { CheckinProfileCard } from '@/components/CheckinProfileCard';
import { useBufferStore } from '@/stores/bufferStore';
import { getLabelValue } from '@/utils/getLabelValue';
import _ from 'lodash';

export function useHubConnection() {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const intervalRef = React.useRef<any>();

  const { toast } = useToast();
  const setPrinter = useSettingsStore(state => state.setPrinter);
  const setPrinters = useSettingsStore(state => state.setPrinters);
  const setToken = useSettingsStore(state => state.setToken);

  const credentials = useSettingsStore(state => state.credentials);
  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const token = useSettingsStore(state => state.token);
  const alwaysPrint = useSettingsStore(state => state.alwaysPrint);
  const printer = useSettingsStore(state => state.printer);
  const breakoutId = useSettingsStore(state => state.breakoutId);
  const location = useSettingsStore(state => state.location);
  const allowReEntry = useSettingsStore(state => state.allowReEntry);
  const direction = useSettingsStore(state => state.direction);
  const onlyBooked = useSettingsStore(state => state.onlyBooked);

  // Persisting buffer for queing up scans if connection is lost
  const buffer = useBufferStore(state => state.buffer);
  const setBuffer = useBufferStore(state => state.setBuffer);

  async function clearSearchInput() {
    const searchInput = document.getElementById('SearchInput') as HTMLInputElement;
    searchInput.value = '';
    const event = new Event('input', {
      bubbles: true,
    });
    searchInput.dispatchEvent(event);
  }

  async function onScanSuccess(badgeNo: string, checkpointData: any) {
    const previousOrderList = queryClient.getQueryData<any[]>(['orderlist', eventId]);
    if (previousOrderList) {
      const index = previousOrderList.findIndex((order: any) => order.badgeNo === badgeNo);

      if (index !== -1) {
        previousOrderList[index].checkins.push(checkpointData);
        queryClient.setQueryData(['orderlist', eventId], previousOrderList);

        toast({
          body: React.createElement(CheckinProfileCard, {
            order: previousOrderList[index],
            checkpointData,
          }),
        });
      }
    }

    clearSearchInput();
  }

  async function onScanRejected(code: string) {
    toast({ title: getLabelValue(code), variant: 'destructive' });
  }

  async function onScan(badgeNo: string, checkpointData: any) {
    const previousOrderList = queryClient.getQueryData<any[]>(['orderlist', eventId]);

    if (previousOrderList) {
      const index = previousOrderList.findIndex((order: any) => order.badgeNo === badgeNo);

      if (index !== -1) {
        previousOrderList[index].checkins.push(checkpointData);
        queryClient.setQueryData(['orderlist', eventId], previousOrderList);
      }
    }
  }

  async function onLogin(response: any) {
    setToken(response.token);

    connection?.invoke(
      'onsiteHandshake',
      {
        ...credentials,
        token: response.token,
      },
      {
        eventId,
        location,
      }
    );

    connection?.invoke(
      'GetActivePrintClients',
      {
        ...credentials,
        token: response.token,
      },
      eventId
    );
    connection?.invoke(
      'getEventStatsPerBreakout',
      {
        ...credentials,
        token: response.token,
      },
      eventId
    );
  }

  async function scan(badgeNo: string) {
    if (!connection || (connection.state !== HubConnectionState.Connected && !alwaysPrint)) return;

    if (!connection || (connection.state !== HubConnectionState.Connected && alwaysPrint)) {
      return toast({ title: 'Ingen anslutning till servern!', variant: 'destructive' });
    }

    if (alwaysPrint && !printer) {
      return toast({ title: 'Ingen skrivare ansluten!', variant: 'destructive' });
    }

    const attendeePassDto = {
      eventId: eventId,
      breakoutId: breakoutId,
      badgeNo,
      exhibitorId: null,
      passingType: direction === 'out' ? 2 : 1,
      scanDevice: location,
      printClient: printer?.clientGuid ?? null,
      allowReEntry,
      onlyBooked,
      add: false,
      location: location,
      timestamp: +Date.now(),
    };

    connection.invoke(
      'scan',
      {
        ...credentials,
        token,
      },
      attendeePassDto
    );
  }

  const handleError = (_: Error) => toast({ title: 'Något gick fel!', variant: 'destructive' });
  const onPrinterConnected = async (printer: Printer) => setPrinter(printer);
  const onPrinterDisconnected = async () => setPrinter(null);
  const onGetActivePrintClients = async (printers: Printer[]) => setPrinters(printers);
  const handleCheckIn = (badgeNo: string) => {
    if (connection?.state === HubConnectionState.Connected && navigator.onLine) {
      scan(badgeNo);
    } else {
      setBuffer([...buffer, badgeNo]);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const newConnection = new HubConnectionBuilder()
          .withUrl(HUB_URL)
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: () => 5000,
          })
          .build();

        setConnection(newConnection);

        await newConnection.start();

        [...buffer].forEach(badgeNo => {
          scan(badgeNo);
          setBuffer([...buffer.filter(b => b !== badgeNo)]);
        });

        newConnection.invoke('getUserToken', credentials.username, credentials.userGuid);
      } catch (error) {
        handleError(error as any);
      }
    })();

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (connection) connection?.stop();
    };
  }, []);

  useEffect(() => {
    if (connection) {
      connection.onreconnected(() => {
        if (buffer.length > 0) {
          [...buffer].forEach(badgeNo => {
            scan(badgeNo);
            setBuffer([...buffer.filter(b => b !== badgeNo)]);
          });
        }
      });
    }
  }, [connection, buffer]);

  useEffect(() => {
    if (connection?.state === HubConnectionState.Connected && buffer.length > 0) {
      [...buffer].forEach(badgeNo => {
        scan(badgeNo);
        setBuffer([...buffer.filter(b => b !== badgeNo)]);
      });
    }
  }, [connection, buffer]);

  useEffect(() => {
    if (connection) {
      connection.on('onLogin', onLogin);
      connection.on('onScanSuccess', onScanSuccess);
      connection.on('onScanRejected', onScanRejected);
      connection.on('onScan', onScan);
      connection.on('onGetActivePrintClients', onGetActivePrintClients);
      connection.on('onPrinterConnected', onPrinterConnected);
      connection.on('onPrinterDisconnected', onPrinterDisconnected);
    }
  }, [connection?.state]);

  return {
    checkin: handleCheckIn,
    isConnected: connection?.state === HubConnectionState.Connected && navigator.onLine,
    isIdle: connection?.state === HubConnectionState.Disconnected,
    isLoading:
      connection?.state === HubConnectionState.Connecting || connection?.state === HubConnectionState.Reconnecting || connection?.state === HubConnectionState.Disconnecting,
  };
}
